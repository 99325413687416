<template>
  <section class="container-fluid mt-4 pso-dashboard">
    <div class="box box-responsive box-transparent">
      <div class="box-header d-flex justify-content-between bg-white">
        <div class="">แผนพลังงานระดับประเทศ</div>
        <div class="d-flex align-items-center">
          <div class="text-light-ipepp mr-3">
            Last modified:
            {{ currentNationalPlan.updated_at | thai_date(true) }}
          </div>
          <button
            class="btn btn-outline-red py-1 px-4"
            @click="showEdit"
            v-if="canEditCurrentNationalPlan"
          >
            แก้ไข
          </button>
        </div>
      </div>
      <div class="box-body">
        <div class="row no-gutters mb-2">
          <div class="offset-md-1 col-md-11 font-weight-bold text-h5">
            แผนระดับประเทศ
          </div>
        </div>
        <div class="row no-gutters align-items-center my-3">
          <div class="col-md-1 font-weight-bold">ยุทธศาสตร์ชาติ 20 ปี</div>
          <div
            class="
              col-md-11
              font-weight-bold
              text-h5
              plan-message
              bg-dark-yellow
              shadow-normal
              py-1
              text-center
            "
            v-html="$options.filters.nl2br(nationalPlan.strategy)"
          ></div>
        </div>
        <div class="row no-gutters align-items-center my-3">
          <div class="col-md-1 font-weight-bold">
            แผนแม่บทภายใต้ยุทธศาสตร์ชาติ 20 ปี
          </div>
          <div
            class="
              col-md-11
              font-weight-bold
              text-h5
              plan-message
              bg-green
              shadow-normal
              py-1
              text-center
            "
            v-html="$options.filters.nl2br(nationalPlan.master_plan)"
          ></div>
        </div>
        <div class="row no-gutters align-items-center my-3">
          <div class="col-md-1 font-weight-bold">
            แผนปฏิรูปประเทศด้านพลังงาน
          </div>
          <div class="col-md-11 text-medium py-1">
            <div class="d-flex">
              <div
                v-for="reform in nationalPlan.reform_plans"
                class="mr-2 bg-white rounded-normal shadow-normal"
                v-bind:key="reform.id"
              >
                <div class="mt-2 pl-2 pr-2 mb-2 message-title border-blue">
                  {{ reform.title }}
                </div>
                <div
                  class="mt-2 pl-2 pr-2 mb-2 prewrap text-light-ipepp"
                  v-html="reform.description"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters mb-2">
          <div
            class="
              offset-md-1
              col-md-11
              font-weight-bold
              text-medium text-center
            "
          >
            {{ nationalPlan.national_strategy_title }}
          </div>
        </div>
        <div class="row no-gutters align-items-center mt-3 mb-0">
          <div class="col-md-1 font-weight-bold">แผนงาน</div>
          <div class="col-md-11 text-medium py-1">
            <div class="row no-gutters">
              <div
                v-for="strategy in nationalPlan.national_strategies"
                class="
                  col
                  mr-2
                  bg-white
                  rounded-normal
                  shadow-normal
                  d-flex
                  align-content-between
                  flex-wrap
                "
                v-bind:key="strategy.id"
              >
                <div
                  class="
                    w-100
                    mt-2
                    pl-2
                    pr-2
                    mb-2
                    message-title
                    border-light-yellow
                    font-weight-bold
                  "
                >
                  {{ strategy.title }}
                </div>
                <div
                  v-if="strategy.tag"
                  class="d-flex flex-wrap mt-2 mb-2 text-light-ipepp"
                >
                  <div
                    v-for="(tag, index) in strategy.tag.split(/,/)"
                    class="mx-2 font-weight-bold"
                    v-bind:key="index"
                  >
                    {{ tag }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters align-items-center mb-3">
          <div class="col-md-1 font-weight-bold">แนวทาง</div>
          <div class="col-md-11 text-medium py-1">
            <div class="row no-gutters">
              <div
                v-for="strategy in nationalPlan.national_strategies"
                class="col mr-2 bg-white rounded-normal shadow-normal"
                v-bind:key="strategy.id"
              >
                <div
                  class="
                    mt-2
                    pl-2
                    pr-2
                    mb-2
                    message-title
                    border-yellow
                    text-light-ipepp
                  "
                  v-html="strategy.approach"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modals-container></modals-container>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import PsoNationalPlanForm from '@/components/PsoNationalPlanForm.vue';
import nationalPlanChangedListener from '../../mixins/nationalPlanChangedListener';

export default {
  name: 'NationalPlan',

  mixins: [nationalPlanChangedListener],

  components: {},

  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters([
      'currentNationalPlan',
      'latestNationalPlan',
      'canEditCurrentNationalPlan',
    ]),
    nationalPlan() {
      return this.currentNationalPlan;
    },
  },
  methods: {
    currentNationalPlanChanged() {},
    showEdit() {
      this.$modal.show(
        PsoNationalPlanForm,
        {
          name: 'national_plan_form',
          nationalPlan: this.nationalPlan,
        },
        {
          name: 'national_plan_form',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
  },
};
</script>

<style lang="scss"></style>
