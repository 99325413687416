<template>
  <div class="mb-4">
    <div class="box">
      <div class="box-header text-h4 font-weight-bold">
        แก้ไข
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
      </div>
      <div class="box-body">
        <div class="pso-national-plan-form">
          <div class="form-group editor">
            <label class="font-weight-bold">ยุทธศาสตร์</label>
            <textarea
              class="form-control"
              v-model="credentials.strategy"
            ></textarea>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">แผนแม่บทภายใต้ยุทธศาสตร์ชาติ</label>
            <textarea
              class="form-control"
              v-model="credentials.master_plan"
            ></textarea>
          </div>
          <div class="form-group">
            <div class="d-flex mb-3">
              <label class="font-weight-bold">ปฏิรูป 6 ด้าน</label>
              <button
                type="button"
                @click="addReform"
                class="btn btn-outline-green py-1 px-2 ml-4"
              >
                <eva-icon name="plus"></eva-icon>
                เพิ่ม
              </button>
            </div>
            <div
              class="row no-gutters mb-2"
              v-for="(reform, index) in credentials.$v.reform_plans.$each.$iter"
              v-bind:key="index"
            >
              <div class="col-md-1 text-center">{{ parseInt(index) + 1 }}.</div>
              <div class="col mr-2">
                <textarea
                  class="form-control h-75"
                  placeholder="หัวข้อ"
                  v-model="reform.title.$model"
                ></textarea>
                <div class="error text-danger" v-if="reform.title.$dirty">
                  <div v-if="!reform.title.required" class="text-danger">
                    Required
                  </div>
                  <div v-if="!reform.title.maxLength" class="text-danger">
                    เกินจำนวนตัวอักษรสูงสุด
                  </div>
                </div>
              </div>
              <div class="col mr-2">
                <trumbowyg
                  v-model="reform.description.$model"
                  :config="config"
                  class="form-control"
                  name="reform[index][description]"
                  placeholder="รายละเอียดด"
                ></trumbowyg>
                <div class="error text-danger" v-if="reform.description.$dirty">
                  <div
                    v-if="!reform.description.required"
                    class="text-description"
                  >
                    Required
                  </div>
                  <div v-if="!reform.description.maxLength" class="text-danger">
                    เกินจำนวนตัวอักษรสูงสุด
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  @click="removeReform(index)"
                  class="btn btn-outline-red py-1 px-4"
                >
                  ลบ
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex mb-3 align-items-baseline">
              <div class="font-weight-bold mr-4">
                หัวข้อยุทธศาสตร์กระทรวงพลังงาน
              </div>
              <input
                type="text"
                class="form-control w-auto flex-fill"
                placeholder="ยุทธศาสตร์กระทรวงพลังงาน ปี ..."
                v-model="credentials.national_strategy_title"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex mb-3">
              <label class="font-weight-bold">แผนแม่บท</label>
              <button
                type="button"
                @click="addStrategy"
                class="btn btn-outline-green ml-4 py-1 px-2"
              >
                <eva-icon name="plus"></eva-icon>
                เพิ่ม
              </button>
            </div>
            <div
              class="mb-2"
              v-for="(strategy, index) in credentials.$v.national_strategies
                .$each.$iter"
              v-bind:key="index"
            >
              <div class="row no-gutters mb-1">
                <div class="col-md-1 text-center">
                  {{ parseInt(index) + 1 }}.
                </div>
                <div class="col mr-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="หัวข้อ"
                    v-model="strategy.title.$model"
                  />
                  <div class="error text-danger" v-if="strategy.title.$dirty">
                    <div
                      v-if="!strategy.title.required"
                      class="text-description"
                    >
                      Required
                    </div>
                    <div v-if="!strategy.title.maxLength" class="text-danger">
                      เกินจำนวนตัวอักษรสูงสุด
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <button
                    @click="removeStrategy(index)"
                    class="btn btn-outline-red py-1 px-4"
                  >
                    ลบ
                  </button>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-10 offset-md-1 pr-md-2">
                  <trumbowyg
                    v-model="strategy.approach.$model"
                    :config="config"
                    class="form-control"
                    placeholder="รายละเอียดด"
                  ></trumbowyg>
                  <div
                    class="error text-danger"
                    v-if="strategy.approach.$dirty"
                  >
                    <div
                      v-if="!strategy.approach.required"
                      class="text-description"
                    >
                      Required
                    </div>
                    <div
                      v-if="!strategy.approach.maxLength"
                      class="text-danger"
                    >
                      เกินจำนวนตัวอักษรสูงสุด
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex mb-3 align-items-baseline">
              <div class="font-weight-bold mr-4">
                หัวข้อยุทธศาตร์การพัฒนาจังหวัด
              </div>
              <input
                type="text"
                class="form-control w-auto flex-fill"
                placeholder="ยุทธศาตร์การพัฒนาจังหวัด ปี ..."
                v-model="credentials.province_development_strategy_title"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex mb-3 align-items-baseline">
              <div class="font-weight-bold mr-4">หัวข้อแผนพลังงานระดับภาค</div>
              <input
                type="text"
                class="form-control w-auto flex-fill"
                placeholder="แผนพลังงานระดับภาค ปี ..."
                v-model="credentials.region_energy_plan_title"
              />
            </div>
          </div>
          <div class="form-group mt-4">
            <label class="font-weight-bold">แผนพลังงานระดับภาค</label>
            <div class="form-row">
              <div
                class="col-12"
                v-for="regionEnergyPlan in credentials.region_energy_plans"
                v-bind:key="regionEnergyPlan.id"
              >
                <label class="">{{ regionEnergyPlan.region.name }}</label>
                <textarea
                  rows="5"
                  class="form-control"
                  v-model="regionEnergyPlan.content"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <div class="form-group text-right mt-3">
          <button v-promise-btn @click="submit" class="btn btn-action">
            ยืนยันการแก้ไข
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
// import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Form from '../modules/form/form';
import { maxLength, required } from 'vuelidate/lib/validators';
import { cloneDeep, nth } from 'lodash';

export default {
  name: 'PsoNationalPlanForm',

  components: {
    Trumbowyg,
    // Multiselect
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    nationalPlan: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      credentials: new Form({
        data: () => {
          return {
            strategy: this.nationalPlan.strategy,
            master_plan: this.nationalPlan.master_plan,
            reform_plans: cloneDeep(this.nationalPlan.reform_plans),
            national_strategies: this.initialNationalStrategies(),
            national_strategy_title: this.nationalPlan.national_strategy_title,
            province_development_strategy_title:
              this.nationalPlan.province_development_strategy_title,
            region_energy_plan_title:
              this.nationalPlan.region_energy_plan_title,
            region_energy_plans: cloneDeep(
              this.nationalPlan.region_energy_plans
            ),
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {
          strategy: {
            required,
          },
          master_plan: {
            required,
          },
          reform_plans: {
            $each: {
              title: {
                required,
                maxLength: maxLength(255),
              },
              description: {
                required,
                maxLength: maxLength(65000),
              },
            },
          },
          national_strategies: {
            $each: {
              title: {
                required,
                maxLength: maxLength(255),
              },
              tag: {},
              approach: {
                required,
                maxLength: maxLength(65000),
              },
            },
          },
        },
      }),
      taggingSelected: [],
      taggingOptions: [],
      content: '',
      config: {
        semantic: false,
        btns: [['fontsize'], ['fontfamily'], ['underline', 'italic', 'bold']],
        plugins: {
          fontfamily: {
            fontList: this.$fontfamilyList,
          },
        },
        // Get options from
        // https://alex-d.github.io/Trumbowyg/documentation
      },
    };
  },

  created() {},

  methods: {
    initialNationalStrategies() {
      const national_strategies = cloneDeep(
        this.nationalPlan.national_strategies
      );
      // national_strategies.forEach(strategy => {
      //   strategy.taggingSelected = strategy.tag
      //     .split(/,/)
      //     .filter(Boolean)
      //     .map(item => {
      //       return {
      //         name: item
      //       };
      //     });
      // });
      return national_strategies;
    },
    addReform() {
      if (this.credentials.reform_plans.length >= 6) {
        return;
      }
      this.credentials.reform_plans.push({
        title: '',
        description: '',
      });
    },
    removeReform(index) {
      this.credentials.reform_plans.splice(index, 1);
    },
    addTag(newTag, index) {
      const tag = {
        name: newTag,
      };
      const strategy = nth(this.credentials.national_strategies, index);
      strategy.taggingSelected.push(tag);
    },
    addStrategy() {
      this.credentials.national_strategies.push({
        title: '',
        approach: '',
        taggingSelected: [],
      });
    },
    removeStrategy(index) {
      this.credentials.national_strategies.splice(index, 1);
    },
    submit() {
      return this.credentials
        .patch('/api/national-plans/' + this.nationalPlan.id)
        .then((data) => {
          this.$store.commit('setNationalPlan', data);
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/variables';

.pso-national-plan-form {
  .trumbowyg-box {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .trumbowyg-box,
  .trumbowyg-editor {
    min-height: 100px !important;
  }

  .multiselect__tags {
    font-size: 1rem;
    padding-top: 0px;
    min-height: 47px;
    border-color: #ced4da;

    .multiselect__placeholder {
      font-size: 1rem;
      padding-top: 0px;
      position: absolute;
      top: 8px;
    }

    .multiselect__input {
      font-size: 1rem;
      margin-top: 12px;
    }

    .multiselect__tag {
      top: 8px;
      color: white;
      background-color: $base-text-color;

      .multiselect__tag-icon:after {
        color: white;
      }
    }
  }
}
</style>
